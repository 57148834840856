export enum UserType {
  USER_CONSUMER_TYPE = 'ConsumerUser',
  USER_RETAILER_TYPE = 'RetailerUser',
  USER_PROFESSIONAL_TYPE = 'ProfessionalUser',
}

export type UserTypeInfo = {
  id: number;
  types: UserType[];
};
