import IItemRow from '../../types/ItemRow';
import { OrderState } from '../OrderState';
import { useTranslation } from 'react-i18next';
import { Typography } from '@photosi/web-design-system';

const ShippingItemRow: React.FC<{ item: IItemRow }> = ({ item }) => {
  const { t } = useTranslation(['orderDetail']);

  return (
    <div className="pds-flex pds-flex-row">
      <div className="pds-flex pds-flex-col md:pds-flex-row pds-flex-1 pds-items-center">
        <div className="md:pds-pr-5 pds-w-full sm:pds-w-7/12 sm:pds-space-y-4 pds-space-y-2">
          <Typography variant="headingXL" className="pds-hidden sm:pds-block">
            {item.productDisplayName}
          </Typography>
          <Typography variant="headingM" className="sm:pds-hidden pds-block">
            {item.productDisplayName}
          </Typography>
          <div className="pds-flex pds-flex-col pds-space-y-1">
            <Typography className="pds-block pds-text-sm pds-leading-4 md:pds-text-base md:pds-leading-5">
              {t('quantity', { quantity: Math.max(item.numberOfCopies ? item.numberOfCopies : 0, item.quantity) })}
            </Typography>
            <Typography className="pds-block pds-text-sm pds-leading-4 md:pds-text-base md:pds-leading-5">
              {t('total', { total: item.totalFullFormattedPrice })}
            </Typography>
          </div>
        </div>
        <div className="pds-order-first pds-w-full md:pds-w-auto md:pds-order-none pds-flex pds-justify-start md:pds-justify-center pds-flex-1 md:pds-pr-5">
          <div>
            <OrderState orderState={item.state} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingItemRow;
