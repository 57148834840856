import { mergeClasses } from '@photosi/web-design-system';

type Props = {
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  children: React.ReactElement;
  className?: string;
};

const OrderStatusBanner: React.FC<Props> = ({ children, leftElement, rightElement, className }) => {
  return (
    <div
      data-testid="cancel-order-banner"
      className={mergeClasses(
        'pds-w-full pds-bg-gray-100 pds-text-left md:pds-text-center pds-flex md:pds-items-center pds-flex-col md:pds-flex-row pds-justify-start md:pds-justify-center md:pds-rounded-full pds-rounded-3xl md:pds-py-4 lg:pds-gap-4 pds-gap-2 md:pds-px-6 pds-px-4 pds-py-4',
        className,
      )}
    >
      {leftElement}
      {children}
      {rightElement}
    </div>
  );
};

export default OrderStatusBanner;
