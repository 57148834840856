import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ErrorCat from '../../assets/error-cat.png';
import { Image } from '../../components/Image';

const NotFound: React.FC = () => {
  const { t } = useTranslation('notFound');

  return (
    <div
      className="pds-max-w-5xl xl:pds-max-w-6xl pds-mx-auto pds-p-4 pds-text-center"
      data-testid="not-found-placeholder"
    >
      <Image alt="error image" src={ErrorCat} />
      <p>{t('pageNotFound')}</p>
      <p>
        <Link to="/">{t('goHome')}</Link>
      </p>
    </div>
  );
};

export default NotFound;
