import { DidomiSDK, IDidomiObject } from '@didomi/react';
import { DIDOMI_AMPLITUDE_CONSENT_ID, DIDOMI_API_KEY, DIDOMI_CONSENT_ID } from './settings';
import { useEffect, useState } from 'react';
import { useGetUserTypes } from './hooks/useGetUserTypes';
import { Amplitude as amplitude } from './utils/amplitude';

export const CookieConsent = () => {
  const [isDidomiReady, setIsDidomiReady] = useState(false);
  const { isSuccess, data, isUserRetailer } = useGetUserTypes();

  useEffect(() => {
    if (isDidomiReady) {
      if (isSuccess) {
        amplitude.init({
          userId: data?.id?.toString() || 'unknown',
          userType: isUserRetailer ? 'retailer' : 'consumer',
        });
      }
    }
  }, [isDidomiReady, isSuccess, data, isUserRetailer]);

  const handleDidomiReady = (didomi: IDidomiObject) => {
    didomi.getUserConsentStatusForAll().vendors.enabled.includes(DIDOMI_AMPLITUDE_CONSENT_ID as never);
    setIsDidomiReady(true);
  };

  return (
    <DidomiSDK
      apiKey={DIDOMI_API_KEY}
      noticeId={DIDOMI_CONSENT_ID}
      iabVersion={2}
      embedTCFStub={true}
      gdprAppliesGlobally={true}
      onReady={handleDidomiReady}
    />
  );
};

export default CookieConsent;
