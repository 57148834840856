import { useQuery } from 'react-query';
import { API_GEOGRAPHIC, API_KEY } from '../../settings';
import { useUser } from '../../contexts/UserContext';
import { GeoInfo } from '../../types/Geographic';

export function useAreaList(country: string | null) {
  const { lang } = useUser();
  const { data, isSuccess, isLoading, isError } = useQuery<GeoInfo[]>(`areaList_${country}`, async () => {
    if (!country) return [];
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Language': lang,
        'x-api-key': `${API_KEY}`,
      },
    };

    const response = await fetch(`${API_GEOGRAPHIC}/areas?countryCode=${country}`, {
      ...options,
    });

    return await response.json();
  });

  return {
    data,
    isSuccess,
    isLoading,
    isError,
  };
}
