import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import IOrderListItem from '../../types/OrderListItem';
import IOrderListPaginationData from '../../types/OrderListPaginationData';
import { API_KEY, API_ORDER_LIST } from '../../settings';

type OrdersData = { items: IOrderListItem[]; pageCount: number; itemsCount: number };

const PAGINATION_HEADER = 'x-pagination';
const PAGE_SEARCH_PARAM = 'page';

const defaultData: OrdersData = { items: [], pageCount: 1, itemsCount: 0 };

export function useOrderList() {
  const { authToken, user, lang } = useUser();

  const [params, setParams] = useSearchParams();

  const currentPage = Number(params.get(PAGE_SEARCH_PARAM)) || 1;

  const { data = defaultData, isError, isLoading } = useQuery(
    ['orderList', currentPage],
    async (): Promise<OrdersData> => {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Accept-Language': lang,
          Authorization: `Bearer ${authToken}`,
          'x-api-key': `${API_KEY}`,
        },
      };
      const response = await fetch(
        `${API_ORDER_LIST}?userId=${user?.id}&currentPage=${currentPage}&orderStates=OnWorking,Shipped,Delivered,Cancelled&softwareCodes=PRINTUP_ANDROID,PRINTUP_IPHONE,PRINTUP_IPAD,MIOFOTOGRAFO`,
        options,
      );

      if (!response.ok) {
        throw new Error('Error while fetching orders');
      }

      const items: IOrderListItem[] = (await response.json()) || [];

      const headerPaginationData = response.headers.get(PAGINATION_HEADER);

      if (headerPaginationData) {
        try {
          const { itemsCount, pageCount } = JSON.parse(headerPaginationData) as IOrderListPaginationData;
          return { items, pageCount, itemsCount };
        } catch (e) {
          console.error(e);
        }
      }

      return { items, pageCount: 1, itemsCount: items.length };
    },
    {
      retry: false,
    },
  );

  const onPageChange = useCallback(
    (nextPage: number) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      const page = `${nextPage}`;
      setParams({ page });
    },
    [setParams],
  );

  return {
    data,
    currentPage,
    onPageChange,
    isError,
    isLoading,
  };
}
