import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAreaList } from '../../hooks/useAreaList';
import { useUpdateShippingAddress } from '../../hooks/useUpdateShippingAddress';
import { ShippingAddress } from '../../types/Address';
import { FormGroup, FormInput, FormSelect } from '../Form';
import {
  ADDRESS_STREET_MAXLENGTH,
  ADDRESS_ADDITIONAL_INFO_MAXLENGHT,
  ADDRESS_CITY_MAXLENGTH,
  ADDRESS_FIRSTNAME_MAXLENGTH,
  ADDRESS_LASTNAME_MAXLENGTH,
  ADDRESS_PHONE_MAXLENGTH,
  ADDRESS_ZIPCODE_MAXLENGTH,
  ADDRESS_NUMBER_MAXLENGTH,
  ADDRESS_CAREOF_MAXLENGHT,
} from '../../settings';
import IOrder from '../../types/Order';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Icon,
  Label,
  Typography,
} from '@photosi/web-design-system';
import { PATHS } from '../../types';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { EditShippingAddressError } from '../EditShippingAddressError';
import { EditShippingAddressSuccess } from '../EditShippingAddressSuccess';
import { useCountryList } from '../../hooks/useCountryList';

type FormTypes = ShippingAddress;
type Props = {
  order: IOrder;
};

const EditShippingAddressModal: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation('editShippingAddress');

  // TODO: Handle the openening of the modal with the router state instead of a specific route
  const shippingAddressURLPattern = `${PATHS.ORDERS}/${PATHS.ORDER_DETAIL}/${PATHS.SHIPPING_ADDRESS}`;
  const isShippingAddressURL = useMatch(shippingAddressURLPattern);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { orderId } = useParams<{ orderId: string }>();

  const { mutate, reset, isLoading, isSuccess, isError } = useUpdateShippingAddress(orderId!);

  const [isFormModalOpen, setIsFormModalOpen] = useState(!!isShippingAddressURL);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(isSuccess);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(isError);

  const orderGUID = order.guid;

  const address = {
    additionalInfo: order.shippingAddressAdditionalInfo,
    careOf: order.shippingAddressCareOf,
    area: order.shippingAddressArea,
    city: order.shippingAddressCity,
    country: order.shippingAddressCountry,
    email: order.shippingAddressEmail,
    firstName: order.shippingAddressFirstName,
    id: order.shippingAddressId,
    lastName: order.shippingAddressLastName,
    number: order.shippingAddressNumber,
    phone: order.shippingAddressPhone,
    street: order.shippingAddressStreet,
    zipCode: order.shippingAddressZipCode,
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    watch,
    control,
  } = useForm<FormTypes>({ defaultValues: { ...address } });

  const onSubmit: SubmitHandler<FormTypes> = data => {
    mutate(
      {
        orderGUID,
        ...data,
      },
      {
        onSuccess: () => {
          setIsFormModalOpen(false);
          setIsSuccessModalOpen(true);

          const desiredUrl = `/orders/${orderId}`;

          if (pathname !== desiredUrl) {
            navigate(desiredUrl);
          }
        },
        onError: () => {
          setIsFormModalOpen(false);
          setIsErrorModalOpen(true);
        },
      },
    );
  };

  const firstName = register('firstName', {
    required: true,
    minLength: 3,
    maxLength: ADDRESS_FIRSTNAME_MAXLENGTH,
  });
  const lastName = register('lastName', {
    required: true,
    minLength: 3,
    maxLength: ADDRESS_LASTNAME_MAXLENGTH,
  });
  const country = register('country', { required: true });
  const area = register('area', { required: true });
  const street = register('street', { required: true, maxLength: ADDRESS_STREET_MAXLENGTH });
  const number = register('number', {
    required: true,
    maxLength: ADDRESS_NUMBER_MAXLENGTH,
  });
  const city = register('city', {
    required: true,
    minLength: 3,
    maxLength: ADDRESS_CITY_MAXLENGTH,
  });
  const zipCodeIsRequired = watch('country') === 'ITA';

  const zipCode = register('zipCode', {
    required: zipCodeIsRequired,
    pattern: zipCodeIsRequired ? /^[0-9]{5}$/ : undefined,
    maxLength: ADDRESS_ZIPCODE_MAXLENGTH,
  });
  const phone = register('phone', { required: true, maxLength: ADDRESS_PHONE_MAXLENGTH });
  const additionalInfo = register('additionalInfo', { maxLength: ADDRESS_ADDITIONAL_INFO_MAXLENGHT });
  const careOf = register('careOf', { maxLength: ADDRESS_CAREOF_MAXLENGHT });
  const countries = useCountryList();
  const areas = useAreaList(watch('country'));

  useEffect(() => {
    if (countries.isSuccess) {
      setValue('country', address.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.isSuccess]);

  useEffect(() => {
    if (areas.isSuccess && areas.data?.find(geo => geo.code === address.area)) {
      setValue('area', address.area);
    } else {
      setValue('area', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas.isSuccess]);

  const dismiss = useCallback(() => {
    setIsFormModalOpen(false);

    const desiredUrl = `/orders/${orderId}`;

    if (pathname !== desiredUrl) {
      navigate(desiredUrl);
    }
  }, [navigate, orderId, pathname]);

  const openFormModal = useCallback(() => {
    setIsFormModalOpen(true);
    setIsErrorModalOpen(false);
    setIsSuccessModalOpen(false);
    reset();

    const desiredUrl = `/orders/${orderId}/shipping-address`;

    if (pathname !== desiredUrl) {
      navigate(desiredUrl);
    }
  }, [navigate, orderId, pathname, reset]);

  return (
    <>
      <Dialog open={isFormModalOpen}>
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            size="md"
            className="pds-mt-2 pds-text-center pds-p-0 pds-font-bold pds-items-center"
            onClick={() => openFormModal()}
            data-testid="openModalButton"
          >
            <Icon name="edit" className="pds-w-6 pds-hidden sm:pds-block" />
            {t('editShippingAddress')}
            <Icon name="arrowLeft" className="pds-w-6 sm:pds-hidden pds-block" />
          </Button>
        </DialogTrigger>
        <DialogContent onCloseClick={() => !isLoading && dismiss()} className="pds-max-w-3xl pds-text-left">
          <DialogHeader>
            <DialogTitle asChild>
              <Typography variant="headingXL" className="pds-text-center pds-mb-4">
                {t('editShippingAddress')}
              </Typography>
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit(onSubmit)} data-testid="edit-address-form">
            <div className="pds-flex pds-flex-wrap">
              <FormGroup>
                <FormInput
                  label={`${t('firstName')} *`}
                  control={control}
                  currentValue={address.firstName}
                  maxLength={ADDRESS_FIRSTNAME_MAXLENGTH}
                  error={errors.firstName && t('firstNameError')}
                  {...firstName}
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  label={`${t('lastName')} *`}
                  control={control}
                  currentValue={address.lastName}
                  maxLength={ADDRESS_LASTNAME_MAXLENGTH}
                  error={errors.lastName && t('lastNameError')}
                  {...lastName}
                />
              </FormGroup>
              <FormGroup>
                <FormSelect label={`${t('country')} *`} {...country} error={errors.country && t('countryError')}>
                  <option key="" value="">
                    ---
                  </option>
                  {countries.data &&
                    countries.data.map(c => (
                      <option key={c.code} value={c.code}>
                        {c.name}
                      </option>
                    ))}
                </FormSelect>
              </FormGroup>
              <FormGroup>
                <FormInput
                  label={t('careOf')}
                  control={control}
                  currentValue={address.careOf}
                  maxLength={ADDRESS_CAREOF_MAXLENGHT}
                  error={errors.careOf && t('fieldError')}
                  data-testid="careOf"
                  {...careOf}
                />
              </FormGroup>
              <FormGroup>
                <FormSelect label={`${t('area')} *`} {...area} error={errors.area && t('areaError')}>
                  <option key="" value="">
                    ---
                  </option>
                  {areas.data &&
                    areas.data.map(a => (
                      <option key={a.code} value={a.code}>
                        {a.name}
                      </option>
                    ))}
                </FormSelect>
              </FormGroup>
              <FormGroup className="md:pds-w-3/5">
                <FormInput
                  label={`${t('street')} *`}
                  control={control}
                  currentValue={address.street}
                  maxLength={ADDRESS_STREET_MAXLENGTH}
                  error={errors.street && t('streetError')}
                  {...street}
                />
              </FormGroup>
              <FormGroup className="md:pds-w-2/5">
                <FormInput
                  label={`${t('number')} *`}
                  control={control}
                  currentValue={address.number}
                  error={errors.number && t('numberError')}
                  maxLength={ADDRESS_NUMBER_MAXLENGTH}
                  {...number}
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  label={`${t('city')} *`}
                  control={control}
                  currentValue={address.city}
                  error={errors.city && t('cityError')}
                  maxLength={ADDRESS_CITY_MAXLENGTH}
                  {...city}
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  label={`${t('zipCode')} ${zipCodeIsRequired ? '*' : ''}`}
                  control={control}
                  currentValue={address.zipCode}
                  error={errors.zipCode && t('zipCodeError')}
                  maxLength={ADDRESS_ZIPCODE_MAXLENGTH}
                  {...zipCode}
                />
              </FormGroup>
              <FormGroup>
                <Label className="pds-text-gray-500">{t('phone')} *</Label>
                <Typography className="pds-text-xs pds-text-gray-500">{t('phoneInfo')}</Typography>
                <FormInput
                  control={control}
                  currentValue={address.phone}
                  maxLength={ADDRESS_PHONE_MAXLENGTH}
                  error={errors.phone && t('phoneError')}
                  {...phone}
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  label={t('additionalInfo')}
                  control={control}
                  currentValue={address.additionalInfo}
                  maxLength={ADDRESS_ADDITIONAL_INFO_MAXLENGHT}
                  error={errors.additionalInfo && t('fieldError')}
                  {...additionalInfo}
                />
              </FormGroup>
              <p className="pds-text-xs pds-text-gray-500">* {t('requiredFields')}</p>
            </div>
            <DialogFooter className="pds-flex pds-gap-2 pds-mt-4 pds-w-full">
              <Button size="md" variant="outline" type="button" onClick={dismiss} disabled={isLoading}>
                {t('cancel')}
              </Button>
              <Button
                size="md"
                type="submit"
                fdata-testid="edit-address-submit"
                isLoading={isLoading}
                disabled={!isDirty}
              >
                {t('submit')}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
      <EditShippingAddressError
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        openFormModal={openFormModal}
      />
      <EditShippingAddressSuccess isOpen={isSuccessModalOpen} setIsOpen={setIsSuccessModalOpen} />
    </>
  );
};

export default EditShippingAddressModal;
