import { useTranslation } from 'react-i18next';
import IOrder from '../../types/Order';
import { formatDate } from '../../utils';
import { DeliveryInfo } from '../DeliveryInfo';
import { Typography } from '@photosi/web-design-system';
import { SupportUrl } from '../SupportUrl';
import OrderStatusBanner from '../OrderStatusBanner/OrderStatusBanner';

type Props = { orderNumber: string; isOrderShippedAndOverdue: boolean } & Pick<
  IOrder,
  'orderState' | 'creationDate' | 'expectedDeliveryDate' | 'guid' | 'isDeliveryDelayed' | 'shippingType'
>;

const ShippingHeader: React.FC<Props> = ({
  orderState,
  orderNumber,
  creationDate,
  expectedDeliveryDate,
  isOrderShippedAndOverdue,
}) => {
  const { t } = useTranslation('orderDetail');

  return (
    <div className="pds-border-b pds-border-400 md:pds-p-0 md:pds-pb-6 pds-mb-6 max-md:pds-hidden">
      <div
        className="
     md:pds-flex pds-flex-col md:pds-flex-row pds-justify-between pds-header pds-hidden"
      >
        <div className="pds-flex pds-flex-col pds-w-full md:pds-w-3/4 sm:pds-w-2/3 pds-mb-5 md:pds-mb-0 pds-space-y-4">
          <Typography variant="headingXL">
            {t('order')} {orderNumber}
          </Typography>
          <div className="pds-flex pds-flex-col pds-space-y-1">
            <span>
              {t('createdOn')}: {formatDate(creationDate)}
            </span>
            <DeliveryInfo orderState={orderState} expectedDeliveryDate={expectedDeliveryDate} />
          </div>
        </div>
        <div className="md:pds-w-1/4 sm:pds-w-full xs:pds-w-full pds-hidden pds-self-center sm:pds-flex pds-flex-col pds-space-y-4">
          <SupportUrl orderNumber={orderNumber} />
        </div>
      </div>
      {isOrderShippedAndOverdue && (
        <OrderStatusBanner className="pds-my-3">
          <Typography
            className="pds-text-[#ff0000] md:pds-text-center "
            data-testid="overdue-shipping-header-order-text"
          >
            <span className="pds-font-bold">{t('shippingDelayed.title')}</span>
            <span> - {t('shippingDelayed.subtitle')}</span>
          </Typography>
        </OrderStatusBanner>
      )}
    </div>
  );
};

export default ShippingHeader;
