import { RadioGroup, RadioGroupItem, Typography } from '@photosi/web-design-system';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AddressRadioGroupProps = {
  sameAddressesWatcher: boolean;
  defaultValue: 'yes' | 'no';
  isUserFirstAccess: boolean;
};

const AddressRadioGroup: React.FC<AddressRadioGroupProps> = ({
  sameAddressesWatcher,
  defaultValue,
  isUserFirstAccess,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation('payment');

  return (
    <div className="pds-flex pds-items-center pds-my-6 pds-justify-between">
      <Typography className="pds-text-left">
        {isUserFirstAccess ? t('billingShippingAddressSame') : t('invoiceAddress')}
      </Typography>
      {isUserFirstAccess && (
        <Controller
          name="sameBillingShippingAddress"
          control={control}
          render={({ field: { onChange } }) => (
            <RadioGroup
              className="pds-flex-row pds-gap-6"
              defaultValue={defaultValue}
              onValueChange={value => {
                onChange(value === 'yes');
              }}
            >
              <RadioGroupItem value="yes" id="yes" data-testid="radio-yes" defaultChecked={sameAddressesWatcher}>
                {t('yes')}
              </RadioGroupItem>
              <RadioGroupItem value="no" id="no" data-testid="radio-no" defaultChecked={!sameAddressesWatcher}>
                {t('no')}
              </RadioGroupItem>
            </RadioGroup>
          )}
        />
      )}
    </div>
  );
};

export default AddressRadioGroup;
