import { RadioGroup, RadioGroupItem } from '@photosi/web-design-system';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CLIENT_TYPE } from '../../../utils/consts/clientType';
import { ClientType } from '../../../types/ClientType';
import { useTranslation } from 'react-i18next';

type UserRadioGroupProps = {
  isCompanyWatcher: boolean;
  clientType?: ClientType;
};

const UserRadioGroup: React.FC<UserRadioGroupProps> = ({ isCompanyWatcher, clientType }) => {
  const { control } = useFormContext();
  const { t } = useTranslation('payment');

  return (
    <Controller
      control={control}
      name="clientType"
      render={({ field: { onChange } }) => (
        <RadioGroup
          className="pds-flex-row pds-gap-6 pds-pb-6 pds-mx-3 pds-border-b pds-mb-6"
          defaultValue={clientType || CLIENT_TYPE.COMPANY}
          onValueChange={value => onChange(value)}
          data-testid="radio-user-type"
        >
          <RadioGroupItem
            value={CLIENT_TYPE.COMPANY}
            id={CLIENT_TYPE.COMPANY}
            data-testid="radio-company"
            defaultChecked={isCompanyWatcher}
          >
            {t('company')}
          </RadioGroupItem>
          <RadioGroupItem
            value={CLIENT_TYPE.PHYSICAL_PERSON}
            id={CLIENT_TYPE.PHYSICAL_PERSON}
            data-testid="radio-physical-person"
            defaultChecked={isCompanyWatcher}
          >
            {t('physical-person')}
          </RadioGroupItem>
        </RadioGroup>
      )}
    />
  );
};

export default UserRadioGroup;
