import { Label, Input, mergeClasses, Typography } from '@photosi/web-design-system';
import React, { HTMLAttributes, InputHTMLAttributes, SelectHTMLAttributes } from 'react';
import { useWatch, Control, FieldValues } from 'react-hook-form';

type WithFormGroup = {
  label?: string;
  error?: string;
};

type WithControl<T extends FieldValues = FieldValues> = {
  control: Control<T>;
  currentValue: string | null;
  maxLength: number;
};

export const FormLabel: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <Label className="pds-w-full pds-text-sm pds-text-gray-500 pds-uppercase">{children}</Label>;
};

export const FormInput = React.forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & WithFormGroup & WithControl
>(({ label, control, maxLength, currentValue, error, ...props }, ref) => {
  const watch = useWatch({
    control: control as Control<any>,
    name: props.name as string,
    defaultValue: currentValue || '',
  });
  return (
    <>
      {label && <Label className="pds-mb-1 pds-text-gray-500">{label}</Label>}

      <div className="pds-flex pds-relative pds-group">
        <Input
          maxLength={maxLength}
          className={mergeClasses(
            'pds-w-full pds-py-2 pds-border-b pds-rounded-none focus:pds-outline-none focus-visible:pds-ring-0',
            error ? 'pds-border-red-400' : 'pds-border-gray-400',
          )}
          type="text"
          ref={ref}
          {...props}
        />
        <span className="pds-flex pds-absolute pds-right-0 pds-text-xs pds-text-gray-500 pds-p-2 pds-py-1">
          {watch?.length} / {maxLength}
        </span>
      </div>

      {error && <FormError>{error}</FormError>}
    </>
  );
}) as <T extends FieldValues>(
  props: InputHTMLAttributes<HTMLInputElement> & WithFormGroup & WithControl<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) => JSX.Element;

export const FormSelect = React.forwardRef<HTMLSelectElement, SelectHTMLAttributes<HTMLSelectElement> & WithFormGroup>(
  ({ children, label, error, ...props }, ref) => {
    return (
      <>
        {label && <Label className="pds-mb-1 pds-text-gray-500">{label}</Label>}

        <select
          className={mergeClasses(
            'pds-flex pds-py-2 pds-px-3 pds-w-full pds-text-sm ring-offset-white placeholder:pds-text-gray-400 focus-visible:pds-outline-none pds-bg-white pds-border-b',
            error ? 'pds-border-red-400' : 'pds-border-gray-400',
          )}
          ref={ref}
          {...props}
        >
          {children}
        </select>

        {error && <FormError>{error}</FormError>}
      </>
    );
  },
);

export const FormGroup: React.FC<HTMLAttributes<HTMLDivElement> & { children?: React.ReactNode }> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={mergeClasses('pds-w-full md:pds-w-1/2 md:pds-p-2 pds-mb-4 md:pds-mb-2', className)} {...props}>
      {children}
    </div>
  );
};

export const FormError: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <Typography className="pds-text-xs pds-text-red-400 pds-mb-2">{children}</Typography>;
};
