import { Outlet } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { useAuth } from '../../hooks/useAuth';
import { Login } from '../../components/Login';
import { Loader } from '../../components/Loader';

const PrivateRoutes: React.FC = () => {
  const [authToken] = useAuth();
  const { user: userData } = useUser();

  const user = userData
    ? {
        id: userData.id,
        email: userData.email,
        name: userData.name,
      }
    : undefined;

  if (!authToken) {
    return <Login />;
  }

  if (!user) {
    return <Loader />;
  }

  return (
    <>
      <section className="pds-max-w-5xl xl:pds-max-w-6xl pds-self-center pds-w-full pds-p-4 pds-flex-1 pds-flex pds-items-center pds-justify-center">
        <Outlet />
      </section>
    </>
  );
};

export default PrivateRoutes;
