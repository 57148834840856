import { Typography, Button } from '@photosi/web-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserBillingData } from '../../hooks';

export const ErrorModalContent: React.FC = () => {
  const { t } = useTranslation('payment');
  const { refetch } = useGetUserBillingData();

  return (
    <div className="pds-h-full pds-flex pds-flex-col ">
      <div className="pds-flex pds-flex-col pds-grow pds-items-center pds-justify-center">
        <Typography variant="headingXL">{t('popup.errorTitle', { ns: 'cancelOrder' })}</Typography>
        <div className="pds-w-96 pds-mt-3">
          <Typography variant="subheadS">{t('popup.errorDescription', { ns: 'cancelOrder' })}</Typography>
        </div>
      </div>
      <Button onClick={() => refetch()} className="pds-min-h-12">
        {t('retry')}
      </Button>
    </div>
  );
};

export default ErrorModalContent;
