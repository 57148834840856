import React from 'react';
import { useTranslation } from 'react-i18next';
import CancelledIcon from '../../assets/orders/cancelled.svg';
import { Image } from '../Image';

type CancelledRowsParams = {
  count: number;
};

const CancelledRows: React.FC<CancelledRowsParams> = ({ count }) => {
  const { t } = useTranslation(['order']);

  const label = t('cancelledRows', { count: count });
  const labelDesktop = t('cancelledRowsDesktop', { count: count });

  return (
    <span className="pds-flex">
      <span className="pds-bg-gray-100 pds-w-9 pds-h-9 pds-p-2 pds-rounded-full pds-flex">
        <Image className="pds-m-auto" src={CancelledIcon} alt={label} width="90%" height="90%" />
      </span>
      <div className="pds-text-base pds-my-auto pds-ml-2 pds-text-gray-600">
        <span className="lg:pds-hidden">{label}</span>
        <span className="pds-hidden lg:pds-block">{labelDesktop}</span>
      </div>
    </span>
  );
};

export default CancelledRows;
