import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { selectLanguage } from './utils';
import * as braze from '@braze/web-sdk';
import { BRAZE_API_KEY, BRAZE_BASE_URL, BRAZE_SERVICEWORKER_LOCATION } from './settings';
import { DATALAYER_NAME, GTM_ID, SENTRY_DSN } from './settings';
import { BrowserRouter as Router } from 'react-router-dom';

import './css/index.css';
import { UserProvider } from './contexts/UserContext';
import { QueryClient, QueryClientProvider } from 'react-query';

import { initI18n } from './i18n';
import { Error } from './components/Error';

braze.initialize(BRAZE_API_KEY, {
  baseUrl: BRAZE_BASE_URL,
  serviceWorkerLocation: BRAZE_SERVICEWORKER_LOCATION,
  manageServiceWorkerExternally: true,
});

braze.requestPushPermission();
braze.openSession();

i18n.use(initReactI18next).init(initI18n);

Sentry.init({ dsn: SENTRY_DSN, integrations: [Sentry.browserTracingIntegration()], environment: process.env.NODE_ENV });

TagManager.initialize({
  gtmId: GTM_ID || '',
  dataLayerName: DATALAYER_NAME,
});

const queryClient = new QueryClient();

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<Error />}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <UserProvider lang={selectLanguage()}>
            <App />
          </UserProvider>
        </QueryClientProvider>
      </Router>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
