import { API_ACCOUNTS } from '../settings';
import { APIError } from '../types/APIError';

const getUserBillingData = async (id: number, apiOptions: any) => {
  const response = await fetch(`${API_ACCOUNTS}/${id}/billing-data`, { ...apiOptions });

  if (!response.ok) {
    const error: APIError = new Error(`${response.status}: ${response.statusText}`);
    error.status = response.status;

    throw error;
  }
  return await response.json();
};

export default getUserBillingData;
