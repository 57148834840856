import { API_ORDER_INVOICE } from '../settings';

const createInvoiceOrder = async (apiOptions: RequestInit, orderNumber: string) => {
  const response = await fetch(`${API_ORDER_INVOICE}`, {
    ...apiOptions,
    body: JSON.stringify({ orderGuid: orderNumber }),
  });

  if (!response.ok) {
    throw new Error(await response.json());
  }
  return await response.json();
};

export default createInvoiceOrder;
