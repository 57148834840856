import { LOGIN_URL } from './settings';
import { selectLanguage } from './utils';

const SUPPORTED_LAGUAGES = ['it', 'es'];

function login() {
  const lang = selectLanguage();
  const redirectLang = SUPPORTED_LAGUAGES.includes(lang) ? lang : 'it';
  window.location.assign(`${LOGIN_URL?.replace('LANG', redirectLang)}?next=${window.location.href}`);
}

export default login;
