import { useQuery } from 'react-query';
import { API_KEY, API_ORDER_SHIPPING_ROWS } from '../../settings';
import { useUser } from '../../contexts/UserContext';
import ShippingRow from '../../types/ShippingRow';

export function useShippingRows(orderId: string) {
  const { authToken, lang } = useUser();

  return useQuery(
    `orderRows_orderInfo_${orderId}`,
    async (): Promise<ShippingRow[]> => {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Accept-Language': lang,
          Authorization: `Bearer ${authToken}`,
          'x-api-key': `${API_KEY}`,
        },
      };
      const response = await fetch(`${API_ORDER_SHIPPING_ROWS}?orderGuid=${orderId}`, { ...options });
      return response.json();
    },
  );
}
