import { Button, buttonVariants, mergeClasses, Typography } from '@photosi/web-design-system';
import { getCancelOrderCounter } from '../../utils';
import { useTranslation } from 'react-i18next';
import IOrderListItem from '../../types/OrderListItem';
import { getFeatureFlagEnabled } from '../../utils/getFeatureFlag';
import { useNavigate } from 'react-router-dom';
import { Amplitude } from '../../utils/amplitude';
import { Timer } from '../Timer';
import OrderStatusBanner from '../OrderStatusBanner/OrderStatusBanner';

type Props = {
  isCancellable: boolean;
  isOnWorking: boolean;
  order: IOrderListItem;
};

const OrderListItemTimer: React.FC<Props> = ({ isCancellable, order, isOnWorking }) => {
  const { t } = useTranslation(['orderList']);
  const isCancelledOrderEnabled = getFeatureFlagEnabled('enableOrderCancellation');
  const isDevEnabled = getFeatureFlagEnabled('betaUserOrderCancellation');
  const showCancelOrder = isCancelledOrderEnabled || isDevEnabled;
  const navigate = useNavigate();

  const { button } = buttonVariants();

  if (isCancellable) {
    return (
      <OrderStatusBanner
        leftElement={
          showCancelOrder ? (
            <Timer
              data-testid="timer"
              startTime={getCancelOrderCounter({
                confirmedAt: order.creationDate,
                cancellableUntil: order.maxCancellationDate ?? '',
              })}
              orderId={order.guid}
            />
          ) : undefined
        }
        rightElement={
          showCancelOrder ? (
            <Button
              className={mergeClasses(button({ variant: 'ghost', className: 'pds-p-0 pds-justify-start' }))}
              onClick={() => {
                Amplitude.orderDeleteLinkClick();
                navigate({ pathname: `${order.guid}/cancel` }, { state: 'my_order' });
              }}
            >
              <Typography className="pds-text-water-500 pds-font-bold">{t('cancelOrder')}</Typography>
            </Button>
          ) : undefined
        }
      >
        <Typography data-testid="cancellation-info-text">
          {t(showCancelOrder ? 'orderCancellationInfo' : 'orderCancellationInfoTimeDisabled')}
        </Typography>
      </OrderStatusBanner>
    );
  }

  if (isOnWorking) {
    return (
      <OrderStatusBanner>
        <Typography data-testid="onworking-timer-text">{t('orderCancellationInfoTimeExpired')}</Typography>
      </OrderStatusBanner>
    );
  }

  return null;
};

export default OrderListItemTimer;
