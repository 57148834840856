import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../Image';
import ErrorCat from '../../assets/error-cat.png';
import { mergeClasses } from '@photosi/web-design-system';

const Error: React.FC = () => {
  const { t } = useTranslation('error');
  return (
    <div
      className="pds-text-center pds-p-4 pds-border pds-rounded pds-border-red-400 pds-bg-red-100"
      data-testid="error"
    >
      <Image alt="error image" src={ErrorCat} />
      <h2>{t('errorOccurred')}</h2>
      <p>{t('tryLater')}</p>
    </div>
  );
};

export const ErrorMessage: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { className, children, ...divProps } = props;
  const classes = mergeClasses('pds-p-1 pds-bg-red-200 pds-text-xs pds-text-red-600 pds-rounded', className);

  return (
    <div className={classes} {...divProps}>
      {children}
    </div>
  );
};

export default Error;
