import { mergeClasses } from '@photosi/web-design-system';
import React from 'react';

/**
 * The shape of the props
 */
type ImageProps = {
  alt: string;
  src: string;
  className?: string;
  width?: string;
  height?: string;
};

/**
 * Component to render an image
 * @param alt - The alt text of the image
 * @param src - The src of the image
 * @param width - The widht of the image
 * @param height - The height of the image
 * @constructor
 */
const Image: React.FC<ImageProps> = ({ alt, src, className, width, height }) => (
  <img className={mergeClasses(className)} alt={alt} src={src} style={{ height, width }} />
);

export default Image;
