import { forwardRef } from 'react';
import { Input, mergeClasses, Typography } from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';

interface InvoiceFormInputProps {
  label?: string;

  errorMessage?: string;
  id?: string;
  placeholder?: string;
  className?: string;
  /**
   * isRequired is to be specified used with register, 'cause required register's prop doesn't work with no native html input
   */
  isRequired?: boolean;
  disabled?: boolean;
}

const InvoiceFormInput = forwardRef<HTMLInputElement, InvoiceFormInputProps>(
  ({ label, id, placeholder, errorMessage, isRequired, className, disabled, ...rest }, ref) => {
    const { t } = useTranslation('payment');
    const classList = mergeClasses('pds-border pds-border-gray-400', errorMessage && 'pds-border-red-400', className);

    return (
      <div className="pds-flex-grow pds-flex-col pds-items-start">
        <Input
          ref={ref}
          placeholder={placeholder}
          id={id}
          className={classList}
          required={isRequired}
          label={label}
          labelPosition="top"
          disabled={disabled}
          {...rest}
        />
        {disabled && <Typography className="pds-text-xs  pds-flex pds-mt-2">{t('nonEditableField')}</Typography>}
        {errorMessage && (
          <Typography className="pds-text-xs pds-text-red-400 pds-flex pds-mt-2">{t(errorMessage)}</Typography>
        )}
      </div>
    );
  },
);

export default InvoiceFormInput;
