import { API_ORDER_DOCUMENT_INVOICE } from '../settings';
import { APIError } from '../types/APIError';
import { APIOptions } from '../types/APIOptions';

const getInvoiceUrl = async (orderId: string, apiOptions: APIOptions) => {
  const response = await fetch(`${API_ORDER_DOCUMENT_INVOICE}/${orderId}`, { ...apiOptions });

  if (!response.ok) {
    const error: APIError = new Error(`${response.status}: ${response.statusText}`);
    error.status = response.status;

    throw error;
  }

  return response.json();
};

export default getInvoiceUrl;
