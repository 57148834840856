enum OrderStateEnum {
  OnWorking = 'OnWorking',
  Shipped = 'Shipped',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
  OnHold = 'OnHold',
  Confirmed = 'Confirmed',
  ReadyToPickup = 'ReadyToPickup',
}

export default OrderStateEnum;
