import { Button, Icon, Typography } from '@photosi/web-design-system';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGetFiscalReceiptUrl } from '../../../../hooks';
import { downloadDocument } from '../../utils/downloadDocument';

type ReceiptButtonProps = {
  orderNumber?: number;
};

const ReceiptButton: React.FC<ReceiptButtonProps> = ({ orderNumber }) => {
  const { t } = useTranslation('payment');
  const fiscalReceiptResponse = useGetFiscalReceiptUrl();
  const fiscalReceiptInProgress =
    fiscalReceiptResponse.isLoading || (fiscalReceiptResponse.isError && fiscalReceiptResponse.is404Error);
  const fiscalReceiptDisabled = fiscalReceiptResponse.isLoading || fiscalReceiptResponse.isError;

  const downloadFiscalReceipt = () =>
    downloadDocument(fiscalReceiptResponse?.data.url as string, `${t('fiscalReceiptForOrderNumber')} ${orderNumber}`);
  const isError = fiscalReceiptResponse.isError && !fiscalReceiptResponse.is404Error;
  const errorIcon = isError ? 'alert' : 'inProgress';

  return (
    <Button
      variant="link"
      leftElement={<Icon name={fiscalReceiptResponse.isSuccess ? 'download' : errorIcon} className="pds-min-w-6" />}
      data-testid="receipt-download-button"
      onClick={downloadFiscalReceipt}
      disabled={fiscalReceiptDisabled}
      className="pds-text-left"
    >
      {fiscalReceiptInProgress ? (
        <Trans i18nKey="fiscalReceiptInProgress" t={t} />
      ) : (
        <Typography>{isError ? t('fiscalReceiptError') : t('downloadFiscalReceipt')}</Typography>
      )}
    </Button>
  );
};
export default ReceiptButton;
