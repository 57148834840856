import { useQuery } from 'react-query';
import { useUser } from '../../contexts/UserContext';
import { API_KEY } from '../../settings';
import getFiscalReceiptUrl from '../../api/getFiscalReceiptUrl';
import { APIError } from '../../types/APIError';
import { useParams } from 'react-router-dom';
import { APIOptions } from '../../types/APIOptions';

export const useGetFiscalReceiptUrl = () => {
  const { authToken, lang } = useUser();
  const { orderId } = useParams();

  const apiOptions: APIOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Accept-Language': lang,
      Authorization: `Bearer ${authToken}`,
      'x-api-key': `${API_KEY}`,
    },
  };
  const { error, ...rest } = useQuery(
    ['fiscalReceiptUrl', orderId],
    () => getFiscalReceiptUrl(orderId as string, apiOptions),
    {
      retry: 0,
    },
  );

  // this const is required 'cause the API returns 404 where document's url is missing. It doesn't indicate that there's an error, but that there's no data
  const is404Error = (error as APIError)?.status === 404;

  return { error, is404Error, ...rest };
};
