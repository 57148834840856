import { useTranslation } from 'react-i18next';
import { formatPaymentType } from '../../utils';
import { Typography } from '@photosi/web-design-system';
import { PaymentDocumentsButtons } from './components/PaymentDocumentsButtons';

type Props = {
  paymentType?: string;
  canceled?: boolean;
  orderId: string;
  showPaymentButtons: boolean;
};

const PaymentDetails: React.FC<Props> = ({ paymentType, canceled, orderId, showPaymentButtons }) => {
  const { t } = useTranslation('payment');

  return (
    <div>
      <Typography variant="headingXL" className="pds-hidden sm:pds-block" data-testid="payment-info-big-screen">
        {t('paymentInfo')}
      </Typography>
      <Typography variant="headingL" className="sm:pds-hidden pds-block" data-testid="payment-info-small-screen">
        {t('paymentInfo')}
      </Typography>
      <div className="pds-text-sm md:pds-text-base md:pds-max-w-80 pds-mt-4">
        {canceled ? t('canceled') : formatPaymentType(paymentType)}
      </div>
      {showPaymentButtons && <PaymentDocumentsButtons orderId={orderId} />}
    </div>
  );
};

export default PaymentDetails;
