import { useQuery } from 'react-query';
import { useUser } from '../../contexts/UserContext';
import { API_ACCOUNTS_USER_TYPES, API_KEY } from '../../settings';
import { UserTypeInfo } from '../../types/UserTypes';
import { isConsumer, isRetailer } from '../../utils/userInfoUtils';

export function useGetUserTypes() {
  const { authToken, lang } = useUser();

  const { data, ...rest } = useQuery(
    'userTypes',
    async (): Promise<UserTypeInfo> => {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Accept-Language': lang,
          Authorization: `Bearer ${authToken}`,
          'x-api-key': `${API_KEY}`,
        },
      };

      const response = await fetch(API_ACCOUNTS_USER_TYPES, { ...options });
      if (response.status !== 200) {
        throw await response.json();
      }
      return response.json();
    },
  );

  const isUserConsumer = isConsumer(data?.types);
  const isUserRetailer = isRetailer(data?.types);

  return {
    ...rest,
    data,
    isUserConsumer,
    isUserRetailer,
  };
}
