import { useQuery } from 'react-query';
import { API_GEOGRAPHIC, API_KEY, ENABLED_SHIPPING_COUNTRIES } from '../../settings';
import { useUser } from '../../contexts/UserContext';
import { GeoInfo } from '../../types/Geographic';

type CountryInfo = {
  isoAlpha3: string;
  name: string;
};

export function useCountryList() {
  const { lang } = useUser();
  const { data, isSuccess, isLoading, isError } = useQuery<GeoInfo[]>(`countryList`, async () => {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Language': lang,
        'x-api-key': `${API_KEY}`,
      },
    };
    const response: CountryInfo[] = await (await fetch(`${API_GEOGRAPHIC}/countries`, { ...options })).json();
    return response
      .filter(c => ENABLED_SHIPPING_COUNTRIES.includes(c.isoAlpha3))
      .map(c => ({
        code: c.isoAlpha3,
        name: c.name,
      }));
  });
  const onlyItaliansCountries = data?.filter(country => country.code !== 'ESP');

  return {
    data,
    isSuccess,
    isLoading,
    isError,
    onlyItaliansCountries,
  };
}
