import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderStateEnum from '../../types/OrderState';
import { parseOrderState } from '../../utils';
import { Chip, Icon, IconName, mergeClasses, Typography } from '@photosi/web-design-system';

type OrderStateParams = {
  orderState: string;
};

type DesignPropsOfOrderState = {
  label: string;
  icon?: IconName;
  borderColor: string;
  textColor: string;
};

const orderStateInfoMap: Record<OrderStateEnum | 'fallback', DesignPropsOfOrderState> = {
  OnHold: {
    label: 'onHold',
    icon: 'thumbUp',
    textColor: 'pds-text-water-500',
    borderColor: 'pds-border-water-500',
  },
  Confirmed: {
    label: 'onHold',
    icon: 'thumbUp',
    textColor: 'pds-text-water-500',
    borderColor: 'pds-border-water-500',
  },
  OnWorking: {
    label: 'onWorking',
    icon: 'stars',
    textColor: 'pds-text-[#F56675]',
    borderColor: 'pds-border-[#F56675]',
  },
  Shipped: {
    label: 'shipped',
    icon: 'deliveryOutline',
    textColor: 'pds-text-iris',
    borderColor: 'pds-border-iris',
  },
  Delivered: {
    label: 'delivered',
    icon: 'check',
    textColor: 'pds-text-green-500',
    borderColor: 'pds-border-green-500',
  },
  Cancelled: {
    label: 'cancelled',
    icon: 'x',
    textColor: 'pds-text-gray-600',
    borderColor: 'pds-border-gray-600',
  },
  ReadyToPickup: {
    label: 'readyToPickUp',
    borderColor: 'pds-border-[#EB5D0B]',
    textColor: 'pds-text-[#EB5D0B]',
    icon: 'package',
  },
  fallback: {
    label: 'MISSING',
    icon: 'x',
    textColor: 'pds-text-gray-600',
    borderColor: 'pds-border-gray-600',
  },
};

const OrderState: React.FC<OrderStateParams> = ({ orderState }) => {
  const { t } = useTranslation(['orderState']);
  const parsedOrderState = parseOrderState(orderState);
  const { label, icon, borderColor, textColor } =
    orderStateInfoMap[parsedOrderState as OrderStateEnum] || orderStateInfoMap.fallback;

  return (
    <Chip
      className={borderColor}
      leftElement={icon && <Icon className={mergeClasses('pds-w-6 pds-h-6', textColor)} name={icon} />}
    >
      <Typography className={mergeClasses(textColor, 'pds-font-bold')}>{t([label])}</Typography>
    </Chip>
  );
};

export default OrderState;
