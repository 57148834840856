import { memo } from 'react';
import RightArrow from '../../assets/right-arrow.svg';
import { useTranslation } from 'react-i18next';

type PaginationProps = {
  currentPage: number;
  pageCount: number;
  onPageChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({ pageCount, currentPage, onPageChange }) => {
  const { t } = useTranslation('orderList');

  if (pageCount <= 1) return null;

  return (
    <div className="pds-flex pds-justify-center pds-items-center pds-py-4 pds-gap-3">
      <button
        className="pds-w-6 pds-h-6 pds-bg-transparent pds-leading-none"
        onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
        data-testid="pagination-previous"
      >
        <img src={RightArrow} alt="Next" className="pds-max-w-full pds-transform pds-rotate-180" />
      </button>
      <div
        className="pds-rounded-full pds-text-primary pds-bg-[#F3F7F7] pds-font-bold pds-py-2 pds-px-8 pds-leading-5"
        data-testid="pagination-pages"
      >
        {currentPage} {t('of')} {pageCount}
      </div>
      <button
        className="pds-w-6 pds-h-6 pds-bg-transparent pds-leading-none"
        onClick={() => currentPage < pageCount && onPageChange(currentPage + 1)}
        data-testid="pagination-next"
      >
        <img src={RightArrow} alt="Next" className="pds-max-w-full" />
      </button>
    </div>
  );
};

export default memo(Pagination);
