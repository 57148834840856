import { useTranslation } from 'react-i18next';
import IOrder from '../../types/Order';
import { formatDate } from '../../utils';
import OrderStateEnum from '../../types/OrderState';

type Props = Pick<IOrder, 'expectedDeliveryDate' | 'orderState'>;

const DeliveryInfo: React.FC<Props> = ({ orderState, expectedDeliveryDate }) => {
  const { t } = useTranslation('orderDetail');

  const orderCancelled = orderState === OrderStateEnum.Cancelled;
  const isDelivered = orderState === OrderStateEnum.ReadyToPickup || orderState === OrderStateEnum.Delivered;

  if (!expectedDeliveryDate) {
    return null;
  }

  return (
    <span
      data-testid="delivery-info-text"
      className="pds-flex pds-justify-between md:pds-justify-start pds-w-full md:pds-w-auto md:pds-gap-1"
    >
      {!orderCancelled && (
        <>
          <span className="pds-font-bold md:pds-font-normal">
            {isDelivered ? `${t('deliveredOn')}:` : `${t('willArriveOn')}:`}
          </span>
          <span>{formatDate(expectedDeliveryDate)}</span>
        </>
      )}
    </span>
  );
};

export default DeliveryInfo;
