import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Icon,
  mergeClasses,
  typographyVariants,
} from '@photosi/web-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserBillingData } from '../../hooks';
import { InvoiceForm } from '../InvoiceForm';
import { Loader } from '../Loader';
import { useGetMutationState } from '../../hooks';
import ErrorModalContent from './ErrorModalContent';
import SuccessModalContent from './SuccessModalContent';
import { Amplitude } from '../../utils/amplitude';

interface InvoiceModalProps {
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({ isOpen = false, setIsOpen }) => {
  const { t } = useTranslation('payment');

  const {
    data: billingData,
    isLoading: isBillingDataLoading,
    isSuccess: isBillingDataSuccess,
    isError: isBillingDataError,
    is404Error,
  } = useGetUserBillingData({
    enabled: isOpen,
  });

  const createUserBillingData = useGetMutationState('createBillingData');
  const updateUserBillingData = useGetMutationState('updateBillingData');

  const billingDataMutationState = {
    isLoading: createUserBillingData === 'loading' || updateUserBillingData === 'loading',
    isError: createUserBillingData === 'error' || updateUserBillingData === 'error',
    isSuccess: createUserBillingData === 'success' || updateUserBillingData === 'success',
  };
  const isNot404Error = isBillingDataError && !is404Error;
  const dialogHeaderClassList = mergeClasses(
    'pds-flex pds-flex-row pds-justify-between pds-items-center pds-px-3',
    (isNot404Error || billingDataMutationState.isSuccess) && 'pds-justify-end',
  );

  const showInvoiceForm = (isBillingDataSuccess || is404Error) && !billingDataMutationState.isSuccess;
  const onTriggerClick = () => {
    setIsOpen(true);
    Amplitude.invoiceModalOpen();
  };

  return (
    <Dialog open={isOpen} modal onOpenChange={setIsOpen}>
      <DialogTrigger asChild data-testid="modal-trigger">
        <Button variant="link" onClick={onTriggerClick} leftElement={<Icon name="file" />}>
          {t('requestInvoice')}
        </Button>
      </DialogTrigger>
      <DialogContent
        className="pds-max-w-[600px] pds-max-h-[630px] pds-h-[630px] pds-justify-start pds-py-8 pds-px-3"
        showCloseIcon={false}
        data-testid="invoice-modal"
      >
        <DialogHeader className={dialogHeaderClassList}>
          <DialogTitle
            className={typographyVariants({ variant: 'bodyM', className: !showInvoiceForm ? 'pds-hidden' : '' })}
          >
            {t('invoiceData')}
          </DialogTitle>
          <Button variant="link" onClick={() => setIsOpen(false)} className="pds-p-0 pds-h-fit">
            <Icon name="x" />
          </Button>
        </DialogHeader>
        {isBillingDataLoading && (
          <div className="pds-h-full pds-flex pds-items-center pds-justify-center">
            <Loader />
          </div>
        )}
        {isNot404Error && <ErrorModalContent />}
        {billingDataMutationState.isSuccess && <SuccessModalContent closeModal={() => setIsOpen(false)} />}
        {showInvoiceForm && <InvoiceForm data={billingData} isUserFirstAccess={is404Error} />}
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceModal;
