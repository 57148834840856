import {
  buttonVariants,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import orderCanceled from '../../assets/dialog/order-canceled.svg';
import { Image } from '../Image';

type Props = {
  isOpened: boolean;
};

const CancelOrderSuccessDialog: React.FC<Props> = ({ isOpened }) => {
  const { t } = useTranslation(['cancelOrder', 'orderList', 'common']);
  const { button } = buttonVariants();

  return (
    <Dialog open={isOpened}>
      <DialogContent showCloseIcon={false} data-testid="dialog-content">
        <div className="pds-flex pds-justify-center pds-items-center pds-mb-4">
          <Image src={orderCanceled} height="100px" width="100px" alt={t('popup.successTitle')} />
        </div>
        <DialogHeader>
          <DialogTitle>{t('popup.successTitle')}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{t('popup.successDescription')}</DialogDescription>
        <div className="pds-mt-8">
          <Link
            className={button({
              variant: 'primary',
              size: 'md',
            })}
            to="/orders"
          >
            {t('popup.detail', { ns: 'cancelOrder' })}
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CancelOrderSuccessDialog;
