import { BillingData } from '../../../types/BillingData';
import IOrder from '../../../types/Order';

export type Address = {
  firstName: string;
  lastName: string;
  country: string;
  zipCode: string;
  area: string;
  city: string;
  street: string;
  number: string;
};

export const checkAddresses = (orderInfo: IOrder, data: BillingData) => {
  if (data === undefined) {
    return true;
  }
  const billingAddress = {
    firstName: data?.name,
    lastName: data?.surname,
    country: data?.country,
    zipCode: data?.zipCode,
    area: data?.area,
    city: data?.city,
    street: data?.street,
  } as Address;

  const orderShippingAddress = {
    firstName: orderInfo?.shippingAddressFirstName,
    lastName: orderInfo?.shippingAddressLastName,
    country: orderInfo?.shippingAddressCountry,
    zipCode: orderInfo?.shippingAddressZipCode,
    area: orderInfo?.shippingAddressArea,
    city: orderInfo?.shippingAddressCity,
    street: orderInfo?.shippingAddressStreet,
  } as Address;

  return Object.keys(orderShippingAddress).every(
    key => orderShippingAddress[key as keyof Address] === billingAddress[key as keyof Address],
  );
};
