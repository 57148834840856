import { useMutation } from 'react-query';
import updateUserBillingData from '../../api/updateUserBillingData';
import { useUser } from '../../contexts/UserContext';
import { API_KEY } from '../../settings';
import { BillingData } from '../../types/BillingData';
import { useParams } from 'react-router-dom';
import { useOrderInfo } from '../useOrderInfo';
import { useUserInfo } from '../useUserInfo';

const useUpdateUserBillingData = () => {
  const { authToken, lang } = useUser();
  const userData = useUserInfo(authToken);
  const { orderId } = useParams() as {
    orderId: string;
  };

  const { data: orderInfo } = useOrderInfo(orderId);
  const paymentType = orderInfo?.paymentType as string;
  const shopCode = orderInfo?.shopCode as string;
  const email = userData?.email as string;

  const options = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      Authorization: `Bearer ${authToken}`,
      'x-api-key': `${API_KEY}`,
    },
  };

  return useMutation({
    mutationKey: 'updateBillingData',
    mutationFn: (data: Partial<BillingData>) =>
      updateUserBillingData(
        userData?.id as number,
        options,
        { ...data, shopCode, paymentType, email },
        orderId as string,
      ),
  });
};

export default useUpdateUserBillingData;
