import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { InvoiceFormInput } from '../../InvoiceFormInput';
import { Icon, Typography } from '@photosi/web-design-system';

type ConsumerFormProp = {
  isUserFirstAccess: boolean;
};

const ConsumerForm: React.FC<ConsumerFormProp> = ({ isUserFirstAccess }) => {
  const { t } = useTranslation('payment');
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="pds-flex pds-flex-col pds-gap-y-6 pds-pb-6">
      <div className="pds-flex pds-gap-x-6 ">
        <InvoiceFormInput
          data-testid="name"
          label={t('name')}
          placeholder={t('name')}
          errorMessage={errors.name?.message as string}
          isRequired
          disabled={!isUserFirstAccess}
          {...register('name')}
        />
        <InvoiceFormInput
          data-testid="surname"
          label={t('surname')}
          placeholder={t('surname')}
          errorMessage={errors.surname?.message as string}
          isRequired
          disabled={!isUserFirstAccess}
          {...register('surname')}
        />
      </div>
      <InvoiceFormInput
        data-testid="fiscalCode"
        id="fiscalCode"
        label={t('fiscalCode')}
        placeholder={t('fiscalCode')}
        errorMessage={errors.fiscalCode?.message as string}
        isRequired
        disabled={!isUserFirstAccess}
        {...register('fiscalCode')}
      />
      <Typography className="pds-flex pds-gap-2 pds-text-gray-600 pds-items-center" variant="bodyS">
        <Icon name="warning" />
        {t('impossibleToModifyData')}
      </Typography>
    </div>
  );
};

export default ConsumerForm;
