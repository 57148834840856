import { useMutation } from 'react-query';
import { API_KEY, API_ORDER_LIST } from '../../settings';
import { CancelOrder } from '../../types/CancelOrder';
import { useUser } from '../../contexts/UserContext';

type Payload = {
  reason: string;
  orderId: string;
};

export const useDeleteHeader = () => {
  const { authToken, lang } = useUser();

  return useMutation(async ({ orderId, reason }: Payload) => {
    const response = await fetch(`${API_ORDER_LIST}/${orderId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': lang,
        Authorization: `Bearer ${authToken}`,
        'x-api-key': `${API_KEY}`,
      },
      body: JSON.stringify({ reason }),
    });
    if (response.status !== 200) {
      throw await response.json();
    }
    return (await response.json()) as CancelOrder;
  });
};
