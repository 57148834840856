import { Select, SelectTrigger, SelectContent, SelectItem, SelectItemText, Label } from '@photosi/web-design-system';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InvoiceFormInput } from '../../InvoiceFormInput';
import { useAreaList, useCountryList } from '../../../hooks';
import { GeoInfo } from '../../../types/Geographic';

const AddressForm: React.FC = () => {
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useFormContext();
  const { t } = useTranslation('payment');
  const { onlyItaliansCountries } = useCountryList();
  const areas = useAreaList(watch('country'));

  return (
    <div className="pds-flex pds-flex-col pds-gap-y-6" data-testid="invoice-address">
      <InvoiceFormInput
        data-testid="street"
        label={t('street')}
        isRequired
        placeholder={t('street')}
        errorMessage={errors.street?.message as string}
        {...register('street')}
      />
      <div className="pds-flex pds-gap-x-6">
        <InvoiceFormInput
          data-testid="city"
          label={t('city')}
          isRequired
          placeholder={t('city')}
          errorMessage={errors.city?.message as string}
          {...register('city')}
        />

        <InvoiceFormInput
          data-testid="zipCode"
          label={t('zipCode')}
          placeholder={t('zipCode')}
          isRequired
          errorMessage={errors.zipCode?.message as string}
          {...register('zipCode')}
        />
      </div>
      <div className="pds-flex pds-gap-x-6">
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, value } }) => (
            <div className="pds-flex pds-flex-col pds-items-start pds-gap-2 pds-grow pds-basis-0">
              <Label>{t('country')} *</Label>
              <Select defaultValue={value} onValueChange={onChange}>
                <SelectTrigger
                  className=" pds-border-b pds-border-gray-400 pds-w-full"
                  placeholder="Select a value..."
                />
                <SelectContent>
                  {onlyItaliansCountries &&
                    onlyItaliansCountries.map((country: { code: string; name: string }) => (
                      <SelectItem key={country.code} value={country.code}>
                        <SelectItemText>{country.code}</SelectItemText>
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
          )}
        />
        <Controller
          control={control}
          name="area"
          render={({ field: { onChange, value } }) => (
            <div className="pds-flex pds-flex-col pds-items-start pds-gap-2 pds-grow pds-basis-0">
              <Label>{t('area')} *</Label>
              <Select defaultValue={value} onValueChange={onChange}>
                <SelectTrigger
                  placeholder="Select a value..."
                  className="pds-border-b pds-border-gray-400 pds-w-full"
                  data-testid="invoice-address-area"
                />
                <SelectContent>
                  {areas.isSuccess &&
                    (areas.data as GeoInfo[]).map((area: { code: string; name: string }) => (
                      <SelectItem key={area.code} value={area.code}>
                        <SelectItemText>{area.name}</SelectItemText>
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default AddressForm;
