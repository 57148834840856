import React, { useState } from 'react';
import { useOrderInfo } from '../../../../hooks';
import { InvoiceModal } from '../../../InvoiceModal';
import { PATHS } from '../../../../types';
import { useMatch } from 'react-router-dom';
import ReceiptButton from './ReceiptButton';
import InvoiceButton from './InvoiceButton';

interface PaymentDocumentsButtonsProps {
  orderId: string;
}

const PaymentDocumentsButtons: React.FC<PaymentDocumentsButtonsProps> = ({ orderId }) => {
  const { data, showReceiptButton, isInvoiceInProgress, isInvoiceRequestable, isInvoiceAvailable } = useOrderInfo(
    orderId,
  );
  const invoiceAddressURLPattern = `${PATHS.ORDERS}/${PATHS.ORDER_DETAIL}/${PATHS.INVOICE}`;
  const isInvoiceAddressURL = useMatch(invoiceAddressURLPattern);
  const [isOpen, setIsOpen] = useState<boolean>(!!isInvoiceAddressURL);

  return (
    <div className="pds-my-6 pds-flex pds-flex-col pds-gap-2 pds-items-start" data-testid="payment-documents-buttons">
      {isInvoiceRequestable && <InvoiceModal isOpen={isOpen} setIsOpen={setIsOpen} />}
      {(isInvoiceAvailable || isInvoiceInProgress) && (
        <InvoiceButton isInProgress={isInvoiceInProgress} orderNumber={data?.number} />
      )}
      {showReceiptButton && <ReceiptButton orderNumber={data?.number} />}
    </div>
  );
};

export default PaymentDocumentsButtons;
