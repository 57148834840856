import { z } from 'zod';
import { CLIENT_TYPE } from '../../../utils/consts/clientType';

const commonFields = {
  street: z.string().min(2, 'invalidStreet').optional(),
  city: z.string().min(1),
  zipCode: z
    .string()
    .min(1)
    .regex(/^[0-9]+$/, 'invalidZipCode'),
  country: z.string().min(1, 'invalidCountry'),
  area: z.string().min(2, 'invalidArea'),
  sameBillingShippingAddress: z.boolean(),
};
const companySchema = z.object({
  clientType: z.literal(CLIENT_TYPE.COMPANY),
  company: z.string().min(2, 'invalidCompanyName'),
  vatCode: z.string().max(13, 'vatCodeTooLong').min(11, 'vatCodeTooShort'),
  fiscalCode: z.string().max(16, 'fiscalCodeTooLong').min(11, 'fiscalCodeTooShort'),
  sdiCode: z.string().min(7, 'invalidSdiCode').optional().or(z.literal('')),
  sdiCodeCheck: z.boolean().default(false),
  pec: z.string().email('invalidPec'),
  ...commonFields,
});
const physicalPersonSchema = z.object({
  clientType: z.literal(CLIENT_TYPE.PHYSICAL_PERSON),
  name: z.string().min(2, 'invalidName'),
  surname: z.string().min(2, 'invalidSurname'),
  fiscalCode: z.string().max(16, 'fiscalCodeTooLong').min(11, 'fiscalCodeTooShort'),
  ...commonFields,
});

export const formSchema = z.discriminatedUnion('clientType', [companySchema, physicalPersonSchema]);
