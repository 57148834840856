import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import OrderStateEnum from '../../types/OrderState';
import { formatDate } from '../../utils';
import { Typography } from '@photosi/web-design-system';

type Props = {
  orderState: string;
  deliveryDate: string | null;
  expectedDeliveryDate?: string | null;
};

const OrderDeliveryDate: React.FC<Props> = ({ orderState, deliveryDate, expectedDeliveryDate }) => {
  const { t } = useTranslation('order');

  const isOrderCanceled = orderState === OrderStateEnum.Cancelled;
  const isOrderDelivered = orderState === OrderStateEnum.ReadyToPickup || orderState === OrderStateEnum.Delivered;

  return (
    !isOrderCanceled && (
      <Typography
        variant="headingM"
        className="pds-font-bold xs:pds-block xs:pds-w-full sm:pds-text-lg md:pds-flex md:pds-gap-1 md:pds-items-center"
        data-testid="delivery-date-will-arrive-on"
      >
        <Trans
          i18nKey={isOrderDelivered ? 'deliveryDateReady' : 'deliveryDate'}
          t={t}
          components={{ s: <p className="pds-font-normal" /> }}
          values={{ date: formatDate(deliveryDate || expectedDeliveryDate) }}
        />
      </Typography>
    )
  );
};

export default OrderDeliveryDate;
