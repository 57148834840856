import React, { createContext, useContext } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useUserInfo } from '../hooks/useUserInfo';
import IUser from '../types/User';

type IUserContext = {
  authToken: string;
  userId: string;
  lang: string;
  user?: IUser;
};

type Props = {
  lang: string;
  children: React.ReactNode;
};

const UserContext = createContext<IUserContext>({
  authToken: '',
  userId: '',
  lang: '',
  user: undefined,
});

// eslint-disable-next-line react-refresh/only-export-components
export function useUser() {
  return useContext(UserContext);
}

export const UserProvider: React.FC<Props> = ({ children, lang }) => {
  const [authToken, userId] = useAuth();
  const userData = useUserInfo(authToken);
  const user = userData
    ? {
        id: userData.id,
        email: userData.email,
        name: userData.name,
      }
    : undefined;

  return <UserContext.Provider value={{ authToken, user, lang, userId }}>{children}</UserContext.Provider>;
};
