import { useMutation, useQueryClient } from 'react-query';
import { useUser } from '../../contexts/UserContext';
import { ShippingAddress } from '../../types/Address';
import Order from '../../types/Order';
import { API_KEY, API_ORDER_LIST } from '../../settings';

type Request = ShippingAddress & {
  orderGUID: string;
};

export const useUpdateShippingAddress = (orderId: string) => {
  const { authToken, lang } = useUser();
  const queryClient = useQueryClient();

  const { mutate, reset, isSuccess, isLoading, isError } = useMutation(
    async (request: Request) => {
      const response = await fetch(`${API_ORDER_LIST}/${request.orderGUID}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': lang,
          Authorization: `Bearer ${authToken}`,
          'x-api-key': `${API_KEY}`,
        },
        body: JSON.stringify({
          shippingAddressFirstName: request.firstName,
          shippingAddressLastName: request.lastName,
          shippingAddressStreet: request.street,
          shippingAddressNumber: request.number,
          shippingAddressZipCode: request.zipCode,
          shippingAddressCity: request.city,
          shippingAddressArea: request.area,
          shippingAddressCountry: request.country,
          shippingAddressPhone: request.phone,
          shippingAddressAdditionalInfo: request.additionalInfo,
          shippingAddressCareOf: request.careOf,
        }),
      });
      if (response.status !== 200) {
        throw new Error('Error updating shipping address');
      }
      return (await response.json()) as Order;
    },
    { onSuccess: () => queryClient.invalidateQueries(`orderInfo_${orderId}`) },
  );

  return {
    mutate,
    reset,
    isSuccess,
    isLoading,
    isError,
  };
};
