import { Outlet } from 'react-router-dom';
import { Header, FooterPayments } from '@photosi/web-design-system';
import { selectLanguage } from '../../utils';
import { HOME_URL } from '../../settings';
import { useGetUserTypes } from '../../hooks/useGetUserTypes';
import CookieConsent from '../../CookieConsent';
import { Loader } from '../../components/Loader';

const AppLayout: React.FC = () => {
  const lang = selectLanguage();
  const { isUserRetailer, isLoading } = useGetUserTypes();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="pds-min-h-screen pds-flex pds-flex-col pds-justify-between">
        <Header urlLogo={`${HOME_URL}/${lang}/`} isBusiness={isUserRetailer} />
        <Outlet />
        <FooterPayments />
      </div>
      <CookieConsent />
    </>
  );
};

export default AppLayout;
