import { InitOptions } from 'i18next';
import it from './it.json';
import en from './en.json';
import nl from './nl.json';
import de from './de.json';
import fr from './fr.json';
import es from './es.json';
import { selectLanguage } from '../utils';

export const initI18n: InitOptions = {
  resources: {
    it,
    en,
    es,
    de,
    fr,
    nl,
  },
  lng: selectLanguage(),
  fallbackLng: 'en',
};
