import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  Typography,
} from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';
import { useGetCancellationReasonList } from '../../hooks/useGetCancellationReasonList';

type Props = {
  onSelectReason: (reason: string) => void;
  onTriggerSelect: (opened: boolean) => void;
};

const CancelOrderReasons: React.FC<Props> = ({ onSelectReason, onTriggerSelect }) => {
  const { t } = useTranslation('cancelOrder');
  const { data } = useGetCancellationReasonList();

  return (
    <div className="!pds-mt-8 pds-flex pds-flex-col pds-gap-4" data-testid="reasons-container">
      <Typography variant="headingL" className="pds-font-bold">
        {t('cancellationReasonTitle')}*
      </Typography>
      <Select onValueChange={onSelectReason} onOpenChange={onTriggerSelect}>
        <SelectTrigger placeholder={t('cancellationReasonPlaceholder')} data-testid="select-trigger" />
        <SelectContent>
          {data?.items
            .filter(reason => !reason.isDisabled)
            .map(reason => (
              <SelectItem key={reason.code} value={reason.code} data-testid="reasons-select-item">
                <SelectItemText>{reason.name}</SelectItemText>
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
      <Typography variant="bodyS">{t('required')}</Typography>
    </div>
  );
};

export default CancelOrderReasons;
