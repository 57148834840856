import { API_KEY, API_ACCOUNTS } from '../../settings';
import IUser from '../../types/User';
import { useQuery } from 'react-query';

export function useUserInfo(userToken: string) {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${userToken}`,
      'x-api-key': `${API_KEY}`,
    },
  };

  const { data } = useQuery<IUser>('user', async () => {
    if (!userToken) {
      return;
    }
    const response = await fetch(`${API_ACCOUNTS}/current`, { ...options });
    return await response.json();
  });

  return data;
}
