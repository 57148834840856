import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Label, Typography, Icon } from '@photosi/web-design-system';
import { Controller, useFormContext } from 'react-hook-form';
import { BillingData } from '../../../types/BillingData';
import { InvoiceFormInput } from '../../InvoiceFormInput';

interface CompanyFormProps {
  data: BillingData;
  isUserFirstAccess: boolean;
}

const CompanyForm: React.FC<CompanyFormProps> = ({ data, isUserFirstAccess }) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useFormContext();

  const { t } = useTranslation('payment');
  const sdiWatcher = watch('sdiCodeCheck');

  return (
    <div className="pds-flex pds-flex-col pds-gap-y-6 pds-pb-6">
      <InvoiceFormInput
        data-testid="company"
        label={t('company')}
        isRequired
        placeholder={t('company')}
        errorMessage={errors.company?.message as string}
        disabled={!isUserFirstAccess}
        {...register('company')}
      />
      <div className="pds-flex pds-gap-x-6">
        <InvoiceFormInput
          data-testid="vatCode"
          label={t('vatCode')}
          isRequired
          placeholder={t('vatCode')}
          errorMessage={errors.vatCode?.message as string}
          disabled={!isUserFirstAccess}
          className="pds-grow"
          {...register('vatCode')}
        />
        <InvoiceFormInput
          data-testid="fiscalCode"
          label={t('fiscalCode')}
          isRequired
          placeholder={t('fiscalCode')}
          errorMessage={errors.fiscalCode?.message as string}
          className="pds-grow"
          disabled={!isUserFirstAccess}
          {...register('fiscalCode')}
        />
      </div>
      <Typography className="pds-flex pds-gap-2 pds-text-gray-600 pds-items-center pds-border-b pds-pb-6" variant="bodyS">
        <Icon name="warning" />
        {t('impossibleToModifyData')}
      </Typography>
      <InvoiceFormInput
        data-testid="sdiCode"
        label={t('sdiCode')}
        placeholder={t('sdiCode')}
        errorMessage={errors.sdiCode?.message as string}
        className="pds-w-full"
        isRequired={!sdiWatcher}
        {...register('sdiCode')}
      />
      <div className="pds-mt-3 pds-flex pds-gap-2 pds-items-center">
        <Controller
          control={control}
          name="sdiCodeCheck"
          render={({ field: { onChange } }) => (
            <Checkbox id="noSdiCode" onCheckedChange={value => onChange(value)} defaultChecked={!!data?.sdiCode} />
          )}
        />
        <Label htmlFor="noSdiCode">{t('noSdiCode')}</Label>
      </div>

      {sdiWatcher && (
        <InvoiceFormInput
          data-testid="pec"
          label={t('pec')}
          isRequired
          placeholder={t('pec')}
          errorMessage={errors.pec?.message as string}
          {...register('pec')}
        />
      )}
    </div>
  );
};

export default CompanyForm;
