import { Icon, Typography } from '@photosi/web-design-system';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import OrderStateEnum from '../../types/OrderState';
import IOrderListItem from '../../types/OrderListItem';
import { useSearchParams } from 'react-router-dom';

type OrdersData = { items: IOrderListItem[]; pageCount: number; itemsCount: number };

type Props = {
  startTime: number;
  orderId?: string;
  onTimerEnd?: () => void;
};

const Timer: React.FC<Props> = ({ startTime, onTimerEnd, orderId }) => {
  const [params] = useSearchParams();
  const currentPage = Number(params.get('page')) || 1;
  const [currentTime, setCurrentTime] = useState(startTime);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (currentTime <= 0) {
      onTimerEnd && onTimerEnd();
      if (orderId) {
        queryClient.setQueryData<OrdersData | undefined>(['orderList', currentPage], cachedData => {
          if (cachedData) {
            const oldOrder = cachedData.items.find(order => order.guid === orderId) as IOrderListItem;
            const newOrderList = [
              { ...oldOrder, orderState: OrderStateEnum.OnWorking },
              ...cachedData.items.filter(order => order.guid !== orderId),
            ].sort((a, b) => Date.parse(b.creationDate) - Date.parse(a.creationDate));

            return { ...cachedData, items: newOrderList };
          }
          return cachedData;
        });
      }

      return;
    }

    const timer = setInterval(() => setCurrentTime(prev => prev - 1000), 1000);
    return () => clearInterval(timer);
  }, [currentPage, currentTime, onTimerEnd, orderId, queryClient]);

  const formatTime = useCallback((currentTime: number) => {
    const minutes = Math.floor((currentTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((currentTime % (1000 * 60)) / 1000);

    if (minutes + seconds <= 0) return null;

    return (
      <Typography className="pds-font-bold pds-flex pds-gap-1 sm:pds-gap-2 pds-items-center">
        <Icon name="alarm" className="pds-w-6" />
        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </Typography>
    );
  }, []);

  return (
    <div data-testid="timer" className="pds-flex pds-gap-2 pds-items-center">
      {formatTime(currentTime)}
    </div>
  );
};

export default Timer;
