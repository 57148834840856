import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useShippingRows } from '../../hooks/useShippingRows';
import { ShippingRow } from '../ShippingRow';
import { Typography } from '@photosi/web-design-system';
import { Loader } from '../Loader';
import { Error } from '../Error';

const ShippingRows: React.FC = () => {
  const { orderId } = useParams() as {
    orderId: string;
  };
  const { status, data } = useShippingRows(orderId);

  const renderShippingRowList = useMemo(
    () => data?.map((shippingRow, index) => <ShippingRow key={`shippingRow_${index}`} data={shippingRow} />),
    [data],
  );

  if (status === 'loading') {
    return <Loader />;
  }

  if (status === 'error') {
    return <Error />;
  }

  return <Typography className="pds-text-sm md:pds-text-base">{renderShippingRowList}</Typography>;
};

export default ShippingRows;
