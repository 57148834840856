import { useState } from 'react';
import { useQueryClient } from 'react-query';

export const useGetMutationState = (mutationKey: string) => {
  const [mutationState, setMutationState] = useState<string>();

  const queryClient = useQueryClient();

  queryClient.getMutationCache().subscribe(event => {
    if (event?.options.mutationKey === mutationKey) setMutationState(event?.state?.status);
  });

  return mutationState;
};
