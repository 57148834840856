import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../Image';
import Spinner from '../../assets/spinner.svg';

const Loader: React.FC = () => {
  const { t } = useTranslation('loader');

  return (
    <div className="pds-flex pds-items-center pds-justify-center pds-my-4" data-testid="loader">
      <Image alt={t('loading')} src={Spinner} />
    </div>
  );
};

export default Loader;
