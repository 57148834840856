import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Typography,
} from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';
import { Image } from '../Image';
import ErrorCat from '../../assets/error-cat.png';

const EditShippingAddressError: React.FC<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  openFormModal: () => void;
}> = ({ isOpen, setIsOpen, openFormModal }) => {
  const { t } = useTranslation('editShippingAddress');

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="pds-text-center pds-flex pds-flex-col pds-items-center">
        <DialogHeader className="pds-text-center">
          <DialogTitle asChild>
            <Typography variant="heading2XL">{t('addressUpdateError')}</Typography>
          </DialogTitle>
          <DialogDescription>
            <Typography>{t('somethingWentWrong')}</Typography>
            <Typography>{t('giveUsAnotherChance')}</Typography>
          </DialogDescription>
        </DialogHeader>
        <Image className="pds-w-40 pds-h-40 pds-my-4" alt="cat in box" src={ErrorCat} />

        <Button
          size="md"
          onClick={() => {
            setIsOpen(false);
            openFormModal();
          }}
        >
          {t('retry')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default EditShippingAddressError;
