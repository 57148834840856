import React from 'react';
import { useTranslation } from 'react-i18next';
import IShippingRow from '../../types/ShippingRow';
import { ShippingItemRow } from '../ShippingItemRow';
import { Button, buttonVariants } from '@photosi/web-design-system';
import { Amplitude } from '../../utils/amplitude';

type ShippingRowProps = {
  data: IShippingRow;
};

const ShippingRow: React.FC<ShippingRowProps> = ({ data }) => {
  const { rows, photosiTrackingUrl } = data;
  const { t } = useTranslation(['order']);
  const { button } = buttonVariants();

  return (
    <div className="md:pds-pl-0 md:pds-pr-0" data-testid="shipping-row">
      <div className="pds-flex md:pds-flex-row pds-flex-col pds-justify-between">
        <div className="pds-flex pds-flex-col pds-w-full md:pds-w-3/4 pds-space-y-10">
          {rows.map(item => (
            <ShippingItemRow key={item.guid} item={item} />
          ))}
        </div>
        <div className="pds-items-stretch md:pds-place-self-center pds-flex-1">
          {!!photosiTrackingUrl && (
            <Button
              rel="noopener noreferrer"
              className={button({
                size: 'md',
                className: 'pds-text-center pds-mt-5 md:pds-mt-0',
              })}
              onClick={() => {
                Amplitude.orderTrackClick({ level: 'product', type: rows.length > 1 ? 'detail' : 'track' });
                window.open(photosiTrackingUrl);
              }}
            >
              {t('trackShipping')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShippingRow;
