import jsCookies from 'js-cookie';
import i18next from 'i18next';
import jwt_decode from 'jwt-decode';
import {
  AVAILABLE_LANGUAGES,
  SUPPORT_FORM_LANGUAGE,
  SUPPORT_FORM_REQUEST_URL,
  LANGUAGE_COOKIE,
  SUPPORT_LOGIN_URL,
  SUPPORT_FORM_BASE_URL,
  SUPPORT_FORM_BASE_URL_RETAIL,
  SUPPORT_LOGIN_URL_RETAIL,
} from './settings';
import OrderStateEnum from './types/OrderState';
import ShippingTypeEnum from './types/ShippingType';
import PaymentTypeEnum from './types/PaymentType';

export const tf_params: string = 'tf_1900000940954';

export function getBrowserLanguage(): string | undefined {
  return navigator.language?.split('-')[0];
}

export function getPhotosiLanguage(): string | undefined {
  return jsCookies.get(`${LANGUAGE_COOKIE}`);
}

export function selectLanguage(): string {
  const photosiLanguage = getPhotosiLanguage();

  if (typeof photosiLanguage !== 'undefined' && AVAILABLE_LANGUAGES.includes(photosiLanguage)) {
    return photosiLanguage;
  }

  const browserLanguage = getBrowserLanguage();

  if (typeof browserLanguage !== 'undefined' && AVAILABLE_LANGUAGES.includes(browserLanguage)) {
    return browserLanguage;
  }
  return AVAILABLE_LANGUAGES[0];
}

export function getUserToken(): string {
  return sessionStorage.getItem('accessToken') || jsCookies.get('userToken') || '';
}

export function getUserId(token: string): string {
  let decoded: {
    sub: string;
  };
  try {
    decoded = jwt_decode(token);
  } catch (error) {
    return '';
  }
  return decoded?.sub || '';
}

export function getCurrentYear(): number {
  return new Date().getUTCFullYear();
}

export function formatDate(date: string | null | undefined): string {
  if (!date) return '-';
  if (!window.Intl || typeof window?.Intl !== 'object') {
    return date;
  }
  const lang = selectLanguage();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return new Intl.DateTimeFormat(lang, options).format(new Date(date));
}

export function isPickUp(shippingType: string | undefined | null) {
  return shippingType === ShippingTypeEnum.PickUp;
}

export function isMailOrder(shippingType: string | undefined | null) {
  return shippingType === ShippingTypeEnum.MailOrder;
}

export function isExpress(shippingType: string | undefined | null) {
  return shippingType === ShippingTypeEnum.Express;
}

export function formatShippingType(value: string | undefined | null) {
  if (isExpress(value)) return i18next.t('shipping:express');
  if (isMailOrder(value)) return i18next.t('shipping:atHome');
  if (isPickUp(value)) return i18next.t('shipping:inShop');
  return '-';
}

export function formatPaymentType(value: string | undefined | null) {
  if (value === PaymentTypeEnum.Online) return i18next.t('payment:onLine');
  if (value === PaymentTypeEnum.InShop) return i18next.t('payment:inShop');
  if (value === PaymentTypeEnum.CashOnDelivery) return i18next.t('payment:cashOnDelivery');
  return '-';
}

export function isInThePast(date: string | Date | null): boolean | undefined {
  if (!date) return;
  return (date instanceof Date ? date : new Date(date)) < new Date();
}

export function parseOrderState(orderState: string) {
  if (orderState === 'OnWorking') return OrderStateEnum.OnWorking;
  if (orderState === 'Shipped') return OrderStateEnum.Shipped;
  if (orderState === 'Delivered') return OrderStateEnum.Delivered;
  if (orderState === 'Cancelled') return OrderStateEnum.Cancelled;
  if (orderState === 'ShipmentRefused') return OrderStateEnum.Shipped;
  if (orderState === 'OnHold' || orderState === 'Confirmed') return OrderStateEnum.OnHold;
  return orderState;
}

// https://b2b-photosi.zendesk.com/access?return_to=https%3A%2F%2Fb2b-photosi.zendesk.com%2Fhc%2Fit%2Frequests%2Fnew%3Ftf_1900000940954%3D958030564528

export function getOrderSupportUrl(orderId: string, isRetail: boolean) {
  const supportFormBaseUrl = isRetail ? SUPPORT_FORM_BASE_URL_RETAIL : SUPPORT_FORM_BASE_URL;
  const supportLoginUrl = isRetail ? SUPPORT_LOGIN_URL_RETAIL : SUPPORT_LOGIN_URL;

  const photosiLanguage = selectLanguage();
  const selectedFormLanguage = SUPPORT_FORM_LANGUAGE[photosiLanguage] || SUPPORT_FORM_LANGUAGE['it'];
  const baseUrlWithLang = `${supportFormBaseUrl}${selectedFormLanguage}${SUPPORT_FORM_REQUEST_URL}?${tf_params}=${orderId}`;
  return supportLoginUrl + encodeURIComponent(baseUrlWithLang);
}

export function isDelivered(orderState: string | undefined) {
  return orderState === OrderStateEnum.Delivered;
}

/**
 * @description In the case of MailOrder we are going to check if order is overdue, we can accept 3 undefined in inpu becuase it will return false.
 * @param orderState
 * @param shippingType
 * @param isDeliveryDelayed
 * @returns if the mailOrder is shippend but user didn't received yet
 */

type Attributes = {
  orderState?: string | null;
  shippingType?: string | null;
  isDeliveryDelayed?: boolean | null;
};

export function isOrderShippedAndOverdue({ orderState, shippingType, isDeliveryDelayed }: Attributes): boolean {
  return orderState === OrderStateEnum.Shipped && isMailOrder(shippingType) && !!isDeliveryDelayed;
}

export function isAppOrder(softwareCode: string) {
  return softwareCode.includes('PRINTUP');
}

export function isValidDate(dateString: string): boolean {
  const date = Date.parse(dateString);
  return !isNaN(date);
}

type CancelCounterInfo = {
  confirmedAt: string;
  cancellableUntil?: string;
};

export function getCancelOrderCounter({ confirmedAt, cancellableUntil }: CancelCounterInfo) {
  const now = Date.now();
  const confirmationDate = new Date(confirmedAt).getTime();
  const cancellableDateObject = new Date(cancellableUntil ?? confirmedAt);
  let cancellableDate = 0;

  if (!cancellableUntil) cancellableDate = cancellableDateObject.setMinutes(cancellableDateObject.getMinutes() + 10);
  else cancellableDate = cancellableDateObject.getTime();

  if (cancellableUntil && !isValidDate(confirmedAt)) {
    return 0;
  }
  if (!isValidDate(confirmedAt)) {
    return 0;
  }

  if (now > cancellableDate) return 0;
  if (confirmationDate > now) return 0;
  return cancellableDate - now;
}
