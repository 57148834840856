import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import login from '../../Auth';
import { Loader } from '../Loader';

const Login: React.FC = () => {
  const { t } = useTranslation(['login']);

  useEffect(() => {
    login();
  });

  return (
    <section
      className="pds-max-w-5xl xl:pds-max-w-6xl pds-mx-auto pds-p-4 pds-flex pds-items-center pds-justify-center"
      data-testid="login-container"
    >
      <Loader />
      <div className="pds-text-center pds-mt-4">{t('awaitAuth')}</div>
    </section>
  );
};

export default Login;
