import { useQuery } from 'react-query';
import getUserBillingData from '../../api/getUserBillingData';
import { useUser } from '../../contexts/UserContext';
import { API_KEY } from '../../settings';
import { useGetUserTypes } from '../useGetUserTypes';
import { APIError } from '../../types/APIError';

type useGetUserBillingDataAttributes = {
  enabled?: boolean;
};

export const useGetUserBillingData = (options?: useGetUserBillingDataAttributes) => {
  const { authToken, lang } = useUser();
  const { data: userData } = useGetUserTypes();

  const apiOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Accept-Language': lang,
      Authorization: `Bearer ${authToken}`,
      'x-api-key': `${API_KEY}`,
    },
  };

  const { data, isError, error, isSuccess, ...rest } = useQuery(
    ['billingData'],
    () => getUserBillingData(userData?.id as number, apiOptions),
    {
      refetchOnWindowFocus: false,
      enabled: options?.enabled,
      retry: 0,
    },
  );

  // this const is required 'cause the API returns 404 when there's no data for that user
  const is404Error = (error as APIError)?.status === 404;

  return { data, isError, error, isSuccess, is404Error, ...rest };
};
