import { Button, buttonVariants, Icon, Typography } from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';
import { useSupportUrl } from '../../hooks/useSupportUrl';
import { Amplitude } from '../../utils/amplitude';

type Props = {
  orderNumber: string;
};

const SupportUrl: React.FC<Props> = ({ orderNumber }) => {
  const { t } = useTranslation('orderDetail');
  const { button } = buttonVariants();
  const { supportUrl } = useSupportUrl(orderNumber);

  async function onClick() {
    await Amplitude.orderHelpClick();
    window.location.href = supportUrl;
  }

  return (
    <Button
      className={button({ variant: 'ghost', className: 'pds-justify-end pds-px-0 hover:pds-bg-transparent' })}
      onClick={onClick}
    >
      <Icon name="message" className="pds-w-6" />
      <Typography className="pds-font-bold">{t('requestSupport')}</Typography>
    </Button>
  );
};

export default SupportUrl;
